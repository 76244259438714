.no-data {
  text-align: center;
  margin-top: 38%;
  font-size: 2em;
  color: #bebbbb;
  user-select: none;
}

// Icons

.icon.is-tiny {
  height: 0.75rem;
  width: 0.75rem;
  i {
    font-size: 12px;
  }
}

// Forms

.optional {
  color: var(--color-grey-lighter);
  font-size: 0.825rem;
  text-transform: uppercase;
  margin-left: 0.125rem;
}

.is-muted {
  color: var(--color-grey-light);
}

.optional.is-small {
  font-size: 0.7rem;
}

label.checkbox > span:last-child {
  margin-left: 0.25rem;
}

label.checkbox.is-small {
  font-size: 0.825rem;
}

input.checkbox[type='checkbox'] {
  width: 0.75rem;
  height: 0.75rem;
}

// Action sepratator

.action-separator {
  display: inline-block;
  background-color: var(--color-grey-lighter);
  width: 0.25rem;
  height: 1.25rem;
  margin: 0 1.125rem;
  border-radius: 3px;
}

// Voice input
:root {
  --voice-player-stop-bg: var(--color-danger);
  --voice-player-bg: var(--color-purple);
  --voice-player-bg-light: var(--color-purple-light);
}
